.exit {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #000000;
  margin-top: 200px;
  cursor: pointer;
}

.textExit {
  margin-right: 10px;
  font-family: "Monserrat-Medium";
}

/* .mobile_burg {
  display: none;
} */
@media screen and (max-width: 1919px) {
  .exit {
    margin-top: 85px;
    font-size: 15px;
  }
  .exit img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 840px) {
  .navigation.active {
    transform: translateX(0);
    transition: all 0.5s;
  }

  .navigation {
    width: 35%;
    display: flex;
    flex-direction: column;

    position: fixed;
    left: 0;
    margin-left: 0px;
    height: 768px;
    background-color: rgb(54, 150, 150);
    z-index: 10;
    transition: all 0.5s;
    transform: translateX(-100%);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .button {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .button.active {
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25),
      0px -3px 9px rgba(0, 0, 0, 0.25);
    background-color: rgba(248, 180, 0, 0.2);
  }
}

@media (max-width: 650px) {
  .navigation {
    width: 40%;
  }
}

@media (max-width: 500px) {
  .navigation {
    width: 55%;
  }
}

@media (max-width: 375px) {
  .navigation {
    width: 70%;
  }
}
