.table_xls {
  display: none
}
.download_table_xls_button {
  cursor: pointer;
  width: 264px;
  height: 60px;
  border: 1px solid #125b50;
  color: white;
  font-family: "Monserrat-Regular";
  font-weight: 600;
  font-size: 20px;
  margin: 0px 19px 0px;
  background-color: #125b50;
  border-radius: 24px;
}
.download_table_xls_button_mini {
  display: none;
}
.download_table_xls_button:hover,
.download_table_xls_button_mini:hover {
  background-color: #125b50;
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px #0c4139;
  transform: translateY(-1px);
}

@media screen and (max-width: 1919px) {
  .download_table_xls_button,
  .download_table_xls_button_mini {
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
}
@media (max-width: 1279px) {
  .download_table_xls_button {
    font-size: 14px;
    width: 135px;
    margin: 0px;
  }
}
@media (max-width: 1024px) {
  .download_table_xls_button {
    display: none;
  }
  .download_table_xls_button_mini {
    display: block;
    align-self: flex-end;
    width: 45px;
    border: 1px solid #125b50;
    color: white;
    font-family: "Monserrat-Regular";
    font-weight: 600;
    font-size: 12px;
    background-color: #125b50;
    border-radius: 50%;
    
  }
}
