.button {
  font-family: "Monserrat-Regular";
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 1px solid rgb(206, 229, 160);
  color: white;
  background-color: rgb(206, 229, 160);
  border-radius: 24px;
  
}

.button:hover {
  background-color: rgb(206, 229, 160);
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px rgb(158, 176, 123);
  transform: translateY(-3px);
}
.active:hover {
  background-color: rgb(121, 162, 41);
  transform: none;
  box-shadow: none;
}

.button[disabled] {
  box-shadow: none;
  transform: none;
  cursor: auto;
}
.active {
  background-color: rgb(121, 162, 41);
}
