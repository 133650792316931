.dropdown-container {
  display: grid;
  width: 264px;
  height: 60px;
  text-align: left;
  border: 1px solid #b7b7b7;
  position: relative;
  border-radius: 8px;
  font-family: "Monserrat-Regular";
  font-weight: 400;
  font-size: 24px;
  margin: 28px 19px 0px;
  padding-left: 10px;
  background-color: white;
}

.dropdown-input {
  max-height: 60px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  overflow: hidden;
  background: rgba(250, 245, 228, 0.2);
}
.dropdown-menu {
  position: absolute;
  left: -1px;
  top: 60px;
  transform: translateY(4px);
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 440px;
  overflow: auto;
  background-color: #fffefa;
  z-index: 1;

  
}
.dropdown-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #a8b8a6;
  border-radius: 5px;
  border: 1px solid rgb(227, 224, 219);
}

.dropdown-selected-value {
  max-height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-item {
  display: flex;
  padding: 5px;
  cursor: pointer;
  justify-content: space-between;
}

.dropdown-item:hover {
  background-color: rgba(209, 241, 172, 0.5);
}

.dropdown-item.selected {
  background-color: rgba(134, 171, 91, 1);
  color: #fff;
}
.delete-icon {
  align-self: center;
  height: 1.1em;
  display: flex;
  align-items: center;
}
.delete-icon:hover {
  background-color: rgba(55, 93, 10, 1);
}
.search-box {
  padding: 5px;
  background-color: #eee;
}
.search-box input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.disabled {
  display: none;
}

@media (max-width: 1919px) {
  .dropdown-container {
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
  .dropdown-menu {
    top: 40px;
    max-height: 265px;
  }
  .dropdown-item {
    padding: 4px;
  }
}

@media (max-width: 1130px) {
  .dropdown-container {
    width: calc(120px + (190 - 120) * ((100vw - 850px) / (1130 - 850)));
    min-width: 120px;
    font-size: 13px;
  }
}
@media screen and (max-width: 625px) {
  .dropdown-container {
    width: 30vw;
    height: 50px;
    min-width: 120px;
    font-size: 13px;
  }
}
@media screen and (max-width: 376px){
  .dropdown-container {
    width: 110px;
    height: 32px;
    margin-top: 24px;
    font-size: 10px;
  }
  .dropdown-tool {
    line-height: 1px;
  }
  .dropdown-tool svg {
    width: 15px;
  }
}
