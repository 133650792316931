.gistogram {
  font-family: "Monserrat-Medium";
  font-weight: 500px;
  display: flex;
  margin: 52px 20px 52px 41px;
  border: 2px solid rgb(243, 242, 242);
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.bar_gistogram {
  width: 902px;
  height: 408px;
  z-index: 1;
}
.doughnut {
  width: 400px;
  height: 400px;
  z-index: 1;
}

.title {
  font-family: "Monserrat-Medium";
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  opacity: 0.7;
  font-style: italic;
  border-radius: 50%;
}
.categories_name {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 902px;
  height: 160px;
  margin: 12px 40px;
  overflow-x: auto;
}
.label_element {
  display: flex;
  margin-bottom: 19px;
  margin-right: 50px;
}
.category_color {
  width: 39px;
  height: 18px;
  border-radius: 8px;
  margin-right: 8px;
}
.category_name {
  font-family: "Monserrat-Medium";
  font-weight: 500;
  font-size: 16px;
}
.sum {
  margin-left: 10px;
}

@media screen and (max-width: 1919px) {
  .bar_gistogram {
    width: 600px;
    height: 280px;
    z-index: 1;
  }
  .categories_name {
    width: 660px;
    height: 120px;
  }
  .category_color {
    width: 28px;
    height: 14px;
    border-radius: 8px;
    margin-right: 8px;
  }
  .label_element {
    margin-bottom: 14px;
  }
  .category_name {
    font-size: 12px;
  }
}
@media screen and (max-width: 1279px) {
  .bar_gistogram {
    width: 400px;
    height: 200px;
    z-index: 1;
  }
  .doughnut {
    width: 300px;
    height: 300px;
  }
  .categories_name {
    width: 500px;
    height: 200px;
  }
}
@media screen and (max-width: 840px) {
  .gistogram {
    margin-left: 80px;
  }
  .categories_name {
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 400px;
    width: 350px;
    overflow-y: auto;

  }
}
@media screen and (max-width: 768px) {
  .bar_gistogram {
    width: 280px;
    height: 500px;
    z-index: 1;
  }
  .gistogram {
    padding: 10px;
    margin: 52px 40px 52px 80px;
  }
  
}
@media screen and (max-width: 625px) {
  .gistogram {
    margin: 20px;
  }
  .categories_name {
    margin: 20px;
  }
}
