.text {
  font-family: "Monserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin: auto auto 10px 20px;
  opacity: 0.8;
}

.transactions {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* height: 150px; */
}

.root {
  width: 100%;
}

.root hr {
  border: 1px solid rgba(84, 120, 43, 0.15);
  box-shadow: 0px -3px 24px rgba(84, 120, 43, 0.5);
  transform: rotate(-0.04deg);
}

.operation {
  display: grid;
  grid-template-columns: 28% 24% 22% 23%;
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 20px;
  text-align: left;
  margin-bottom: 8px;
}

/* .operation_list_item {
} */
.icons {
  display: flex;
}
.operation_list_icon1 {
  background-image: url("../../Images/Icon1.svg");
  width: 22px;
  height: 22px;
  margin-right: 40px;
  background-size: 22px;
  border-radius: 50%;
  cursor: pointer;
}
.operation_list_icon2 {
  background-image: url("../../Images/Icon2.svg");
  width: 19px;
  height: 20px;
  background-size: 19px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  bottom: 0.7px;
}
.operation_list_icon1:hover,
.operation_list_icon2:hover {
  box-shadow: 0px 2px 5px 1px #0c4139;
  transform: translateY(-2px);
}
.icon_button {
  border: none;
  background-color: white;
}

@media screen and (max-width: 1919px) {
  .text {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .operation {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  .operation_list_icon1 {
    width: 16px;
    height: 16px;
    background-size: 16px;
    border-radius: 50%;
  }
  .operation_list_icon2 {
    width: 16px;
    height: 17px;
    background-size: 16px;
    border-radius: 4px;
  }
  .operation_list_icon1:hover,
  .operation_list_icon2:hover {
    box-shadow: 0px 2px 5px 1px #0c4139;
    transform: translateY(-2px);
  }
  .icons {
    align-items: flex-start;
  }
}
@media screen and (max-width: 1040px) {
  .operation {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .operation {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 3px;
    display: grid;
    grid-template-columns: 35% 25% 25% 15%;
    padding-left: 0px;
  }
  .operation_list_item1 {
    padding-left: 10px;
  }
  .operation_list_item {
    padding-left: 10px;
  }
  .operation_list_icon1,
  .operation_list_icon2 {
    margin-right: 12px;
  }
  .icons {
    align-items: flex-start;
  }
}
@media (max-width: 460px) {
  .operation {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 3px;
    display: grid;
    padding-left: 0px;
  }
  .operation_list_item1 {
    padding-left: 10px;
  }
  .operation_list_item {
    padding-left: 10px;
  }
  .operation_list_icon1,
  .operation_list_icon2 {
    margin-right: 12px;
  }
  .icons {
    align-items: flex-start;
  }
}

@media (max-width: 420px) {
  .operation {
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 2px;
    display: grid;
    padding-left: 0px;
  }
  .operation_list_item1 {
    padding-left: 10px;
  }
  .operation_list_item {
    padding-left: 10px;
  }
  .operation_list_icon1,
  .operation_list_icon2 {
    margin-right: 12px;
  }
  .icons {
    align-items: flex-start;
  }
}
@media (max-width: 400px) {
  .operation {
    font-size: 9px;
  }
}
@media (max-width: 376px) {
  .text {
    font-size: 14px;
  }
  
  
}
