.diagram_box {
  display: flex;
}
.diagrams {
  display: flex;
  margin: 52px 25px 52px 40px;
}
.doughnut {
  width: 400px;
  height: 400px;
  z-index: 1;
}

.title {
  font-family: "Monserrat-Medium";
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  opacity: 0.7;
  font-style: italic;
  border-radius: 50%;
}

.pie {
  display: flex;
}
.categories_name {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 500px;
  width: 500px;
  margin-top: 52px;
  overflow-y: auto;
}
.label_element {
  display: flex;
  margin-bottom: 19px;
  margin-right: 20px;
}
.category_color {
  width: 39px;
  height: 18px;
  border-radius: 8px;
  margin-right: 8px;
}
.category_name {
  font-family: "Monserrat-Medium";
  font-weight: 500;
  font-size: 16px;
}
.sum {
  margin-left: 10px;
}

@media screen and (max-width: 1919px) {
  .diagram_box {
    flex-direction: column;
  }
  .diagrams {
    flex-direction: column;
    margin: 35px auto 24px 40px;
  }
  .doughnut {
    width: 400px;
    height: 400px;
  }
  .categories_name {
    flex-wrap: wrap;
    width: 660px;
    margin-top: 0px;
    height: 160px;
    margin-left: 40px;
  }
  .category_color {
    width: 28px;
    height: 14px;
    border-radius: 8px;
    margin-right: 8px;
  }
  .label_element {
    margin-bottom: 0px;
    margin-top: 14px;
  }
  .category_name {
    font-size: 12px;
  }
}
@media screen and (max-width: 1280px) {
  .diagrams {
    flex-wrap: wrap;
  }
  .pie {
    margin-bottom: 10px;
  }
  .doughnut {
    width: 300px;
    height: 300px;
  }
  .categories_name {
    width: 500px;
    height: 250px;
  }
  .category_color {
    width: 28px;
    height: 14px;
    border-radius: 8px;
    margin-right: 8px;
  }
  .category_name {
    font-size: 12px;
  }
}
@media (max-width: 840px) {
  .diagrams {
    margin: 80px;
  }
  .doughnut {
    width: 300px;
    height: 300px;
  }
  .categories_name {
    flex-wrap: nowrap;
    margin-left: 80px;
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 625px) {
  .diagrams {
    margin: 20px 0px 20px 20px;
  }
  .categories_name {
    margin-left: 20px;
    width: 350px;
    height: 400px;
  }
}
