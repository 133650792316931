.calculator_main_page {
    position: relative;
    bottom: 105px;
}
.title {
    font-family: "Monserrat-Medium";
    font-size: 24px;
    font-weight: 400;
    margin: 0px 40px 20px;
    text-align: start;
}
.choice_buttons_block {
    display: flex;
    margin-left: 40px;
}
.calculator_page {
    display: flex;
}
.input_block {
    display: flex;
    flex-direction: column;
    margin: 20px 40px 5px;
    font-family: "Monserrat-Regular";
    font-weight: 400;
    font-size: 14px;
}
.input_label {
    align-self: flex-start;
}
.percent_button_block {
    margin-left: 36px;
}
.calculator_button {
    display: flex;
    margin: 20px 40px;
}

.rates_block {
    font-family: "Monserrat-Regular";
    font-weight: 400;
    font-size: 14px;
    margin: 20px 40px;
}

@media (max-width: 840px) {
    .calculator_main_page {
        bottom: 0px
    }
    .calculator_page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .choice_buttons_block {
        justify-content: space-around;
    }
    .title {
        text-align: center;
    }
}
@media (max-width: 624px) {
    .title {
        text-align: start;
    }
    .choice_buttons_block {
        justify-content: flex-start;
    }
    .calculator_page {
        align-items: start;
    }
    .result_block {
        margin-left: 40px;
    }
}
@media (max-width: 376px) {
    .calculator_main_page {
        padding-top: 40px;
    }
}