@font-face {
  font-family: "Monserrat-Regular";
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Monserrat-Medium";
  src: url("../fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Monserrat-SemiBold";
  src: url("../fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "PlayfairDisplay-Medium";
  src: url("../fonts/PlayfairDisplay-Medium.ttf");
}
@font-face {
  font-family: "PlayfairDisplay-ExtraBold";
  src: url("../fonts/PlayfairDisplay-ExtraBold.ttf");
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", "Playfair Display" sans-serif,
    "Montserrat", "Montserrat-Medium";
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #a8b8a6;
  border-radius: 5px;
  border: 1px solid rgb(227, 224, 219);
}
