.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input::placeholder {
  opacity: 0.5;
  color: #b7b7b7;
}

.logo {
  text-align: left;
  padding-left: 120px;
}

.regist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 157px;
}
.regist h1 {
  font-family: "Monserrat-medium";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  /* letter-spacing: 0.06em; */
  /* text-transform: capitalize; */
  margin-bottom: 48px;
  color: #125b50;
}
.registFon {
  position: relative;
  background-image: url("./../../../../src/Images/fonGirl.svg");
  width: 100%;
  height: 1080px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form label {
  box-sizing: border-box;
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #b7b7b7;
}

.input {
  box-sizing: border-box;
  font: 24px/16px "Monserrat-Medium";
  width: 408px;
  height: 67px;
  padding-left: 10px;

  border: 1px solid #b7b7b7;
  border-radius: 10px;
  margin-bottom: 24px;
}

.inputError {
  box-sizing: border-box;
  font: 24px/16px "Monserrat-Medium";
  width: 408px;
  height: 67px;
  padding-left: 10px;
  border: 1px solid red;
  border-radius: 10px;
  outline: none;
  margin-bottom: 5px;
}
.error, 
.only_lat_message {
  color: red;
  max-width: 400px;
  font-family: "Monserrat-Medium";
}

.btn {
  margin-top: 76px;
  background: #125b50;
  border-radius: 60px;
  padding: 12px 20px;
  font-family: "Monserrat-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  border: none;
  text-transform: capitalize;
  color: #faf5e4;
  cursor: pointer;
}
.btn_link {
  font-family: "Monserrat-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #faf5e4;
  text-decoration: none;
}

.btn:hover {
  background-color: #125b50;
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px #0c4139;
  transform: translateY(-1px);
}

.btn[disabled="disabled"],
.btn:disabled {
  opacity: 0.7;
  color: linen;
  background-color: gray;
  cursor: default;
}

.btn:disabled:hover {
  box-shadow: none;
  transform: translateY(0px);
}
.recovery {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #125B50;
  text-decoration: none;
  margin-top: 40px;
}
.message {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #125B50;
  text-decoration: none;
  width: 400px;
  /* margin-top: 40px; */
}
.icon {
  width: 34px;
  height: 34px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 17px;
  opacity: 0.5;
}

.icon:hover {
  opacity: 1;
}
.pass {
  position: relative;
}

@media screen and (max-width: 1919px) {
  .registFon {
    height: 768px;
    background-size: cover;
  }
  .logo {
    text-align: center;
    padding-left: 0px;
  }
  .regist {
    margin-top: 110px;
  }
  .regist h1 {
    font-size: 24px;
  }
  .form label {
    font-size: 12px;
    line-height: 18px;
  }
  .input,
  .inputError {
    width: 290px;
    height: 40px;
    font: 16px/16px "Monserrat-Medium";
  }
  .inputError {
    margin-bottom: 8px;
  }
  .error,
  .only_lat_message,
  .recovery {
    max-width: 290px;
    font-size: 12px;
  }
  .recovery {
    line-height: 20px;
  }
  .pass {
    position: relative;
    display: block;
    align-self: flex-start;
  }

  .icon {
    width: 23px;
    height: 20px;
    top: 12px;
  }
  .textReg {
    font-size: 12px;
  }
  .btn {
    align-self: center;
    font-size: 20px;
    padding: 2px 10px;
    width: 190px;
  }
  .reg {
    font-size: 14px;
  }
  .message {
    width: 290px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1180px) {
  .registFon {
    background-size: cover;
    text-align: center;
    margin-top: 100px;
    height: calc(400px + (650 - 400) * ((100vw - 550px) / (1130 - 550)));
    background-repeat: no-repeat; /* Do not repeat the image */
  }
}

@media screen and (max-width: 720px) {
  .root {
    display: flex;
    flex-direction: column-reverse;
  }

  .registFon {
    background-position-x: 20px;
    max-width: 255px;
    max-height: 255px;
    margin: 0px auto;
  }

  .regist {
    margin: 0px auto;
  }
}
