.button {
  cursor: pointer;
  width: 264px;
  height: 60px;
  border: 1px solid rgb(206, 229, 160);
  color: white;
  font-family: "Monserrat-Regular";
  font-weight: 600;
  font-size: 20px;
  background-color: rgb(206, 229, 160);
  border-radius: 24px;
}

.button:hover {
  background-color: rgb(206, 229, 160);
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px rgb(158, 176, 123);
  transform: translateY(-1px);
}

.button[disabled] {
  box-shadow: none;
  transform: none;
  cursor: auto;
}

@media screen and (max-width: 1919px) {
  .button {
    width: 200px;
    height: 40px;
    font-size: 16px;
  }
}
@media (max-width: 1024px) {

}