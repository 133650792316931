.root {
  display: grid;

  grid-template-columns: 1fr 1fr;
}

.logo {
  text-align: left;
  padding-left: 120px;
}

.regist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 126px;
}
.regist h1 {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.06em;
  text-transform: capitalize;

  color: #125b50;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form label {
  box-sizing: border-box;
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #b7b7b7;
}
.password_recovery {
  display: flex;
  justify-content: space-between;
}
.recovery {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 29px;
  color: #125B50;
  text-decoration: none;
}

.input {
  box-sizing: border-box;
  font: 24px/16px "Arial";
  width: 408px;
  height: 67px;
  padding-left: 10px;
  background-color: white;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
}

.input::placeholder {
  opacity: 0.5;
  color: #b7b7b7;
}

.error,
.only_lat_message {
  color: red;
  max-width: 400px;
  font-family: "Monserrat-Medium";
}

.btn {
  margin-top: 60px;
  background: #125b50;
  border-radius: 60px;
  padding: 12px 20px;
  font-family: "Monserrat-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  border: none;
  text-transform: capitalize;
  color: #faf5e4;
  cursor: pointer;
  appearance: "primary";
}

.btn:hover {
  background-color: #125b50;
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px #0c4139;
  transform: translateY(-1px);
}

.btn[disabled="disabled"],
.btn:disabled {
  opacity: 0.7;
  color: linen;
  background-color: aqua;
}

.registFon {
  position: relative;
  background-image: url("./../../../../src/Images/fonGirl.svg");
  width: 100%;
  height: 1080px;
}

.textFon {
  position: absolute;
  width: 679px;
  right: 201px;
  top: 682px;
  bottom: 244px;
  text-align: left;
}

.welcome {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 77px;
  letter-spacing: 0.1em;

  color: #faf5e4;
}
.textConcept {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1em;

  color: #faf5e4;
}

.textReg {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.09em;
  opacity: 0.8;
  color: #000000;
}

.reg {
  font-size: 20px;
  text-decoration: none;
  color: teal;
}
.reg:hover {
  font-weight: bold;
  color: teal;
}

/* .reg:hover {
  font-weight: bold;
  color: teal;
  text-shadow: 0 1px 0 teal, 0 2px 0 teal, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 teal, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  display: inline-block;
  color: rgb(58, 112, 112);
  font-family: "Russo One", sans-serif;
} */

.reply {
  font: italic 20px/25px Arial;
  color: red;
  max-width: 400px;
}

.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.5;
}

.icon:hover {
  opacity: 1;
}
.pass {
  position: relative;
}

@media screen and (max-width: 1919px) {
  .registFon {
    height: 768px;
    background-size: cover;
  }
  .logo {
    text-align: center;
    padding-left: 0px;
  }
  .regist {
    margin-top: 110px;
  }
  .regist h1 {
    font-size: 24px;
  }
  .form label {
    font-size: 12px;
    line-height: 18px;
  }
  .input {
    width: 290px;
    height: 40px;
    font: 16px/16px "Monserrat-Medium";
  }
  .error,
  .only_lat_message,
  .recovery {
    max-width: 290px;
    font-size: 12px;
  }
  .recovery {
    line-height: 20px;
  }
  .pass {
    position: relative;
    display: block;
    align-self: flex-start;
  }

  .icon {
    width: 23px;
    height: 20px;
    top: 12px;
  }
  .textReg {
    font-size: 12px;
  }
  .btn {
    align-self: center;
    font-size: 20px;
    padding: 2px 10px;
    width: 190px;
  }
  .reg {
    font-size: 14px;
  }
  .reply {
    font-size: 12px;
  }
}

@media screen and (max-width: 1180px) {
  .registFon {
    background-size: cover;
    text-align: center;
    margin-top: 100px;
    height: calc(400px + (650 - 400) * ((100vw - 550px) / (1130 - 550)));
    background-repeat: no-repeat; /* Do not repeat the image */
  }
}

@media screen and (max-width: 720px) {
  .root {
    display: flex;
    flex-direction: column-reverse;
  }

  .registFon {
    background-position-x: 20px;
    max-width: 255px;
    max-height: 255px;
    margin: 0px auto;
  }

  .regist {
    margin: 0px auto;
  }
}
