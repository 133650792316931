.-weekend- {
  color: rgb(187, 39, 39);
  font-weight: bold;
}

.input {
  cursor: pointer;
  position: inherit;
  padding: 10px;
  padding-right: 20px;
  border-radius: 8px;
  z-index: 1;
  border: 2px solid #999999;
  width: 264px;
  font-family: "Monserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  background: none;
}

.input::placeholder {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}

.rootInput {
  position: relative;
  color: #000000;
  display: inline-block;
}

.vect {
  position: absolute;
  top: 16px;
  right: 10px;
  color: #000000;
}

.air-datepicker-body--day-name {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.-day- {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.air-datepicker-nav--title {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  background: none;
}
@media screen and (max-width: 1919px) {
  .rootInput {
    width: 190px;
    height: 40px;
  }
  .input {
    width: 174px;
    font-size: 16px;
    padding: 8px;
  }
  .input::placeholder {
    font-size: 16px;
  }
  .air-datepicker-nav--title {
    font-size: 16px;
  }
  .vect {
    width: 18px;
    height: 18px;
    top: 12px;
    right: 8px;
  }
  .-day- {
    font-size: 12px;
    line-height: 16px;
  }
  .air-datepicker-nav--title {
    font-size: 16px;
  }
  
}
@media screen and (max-width: 840px) {
  .air-datepicker-nav {
    width: 140px;
  }
  .air-datepicker-nav--action {
    width: 140px;
  }
  
  .air-datepicker-timepicker {
    width: 140px;
  }
  .air-datepicker-buttons {
    width: 170px;
  }
  .air-datepicker-body {
    width: 140px;
  }
  .air-datepicker {
    
    width: 170px;
  }
  .air-datepicker-nav--title {
    width: 140px;
    font-size: 10px;
    line-height: 14px;
  }
  .-month- {
    width: 110px;
  }
  .-day- {
    font-size: 10px;
    line-height: 14px;
  }
  .input {
    font-size: 14px;
  }
  
}
@media screen and (max-width: 376px) {
  
  .air-datepicker-nav {
    width: 130px;
  }
  .air-datepicker-nav--action {
    width: 110px;
  }
  
  .air-datepicker-timepicker {
    width: 110px;
  }
  .air-datepicker-buttons {
    width: 110px;
  }
  .air-datepicker-body {
    width: 120px;
  }
  .air-datepicker {
    width: 130px;
  }
  .air-datepicker-nav--title {
    width: 110px;
    font-size: 10px;
    line-height: 14px;
  }
  .-month- {
    width: 110px;
  }
  .-day- {
    font-size: 10px;
    line-height: 14px;
  }
  .rootInput {
    width: 110px;
    height: 28px;
  }
  .input {
    width: 100px;
    height: 18px;
    padding: 5px;
  }
  .input::placeholder {
    font-size: 10px;
  }
  .vect {
    width: 15px;
    height: 15px;
    top: 7px;
    right: -20px;
  }
  .input {
    font-size: 10px;
    width: 125px;
  }
}
