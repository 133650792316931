.result_line_block {
    font-family: "Monserrat-Regular";
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin: 20px 0px;
}

@media screen and (max-width: 1280px) {
    .result_line_block {
        width: 250px;
        font-size: 12px;
    }
}