.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.input::placeholder {
  opacity: 0.5;
  color: #b7b7b7;
}

.logo {
  text-align: left;
  padding-left: 120px;
}

.regist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.regist h1 {
  font-family: "Monserrat-medium";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.06em;
  text-transform: capitalize;
  margin-bottom: 20px;
  color: #125B50;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form label {
  box-sizing: border-box;
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #b7b7b7;
}

.input {
  box-sizing: border-box;
  font: 24px/16px "Monserrat-Medium";
  width: 408px;
  height: 67px;
  padding-left: 10px;

  border: 1px solid #b7b7b7;
  border-radius: 10px;
}

.inputError {
  box-sizing: border-box;
  font: 24px/16px "Monserrat-Medium";
  width: 408px;
  height: 67px;
  padding-left: 10px;
  border: 1px solid red;
  border-radius: 10px;
  outline: none;
}

.error, 
.only_lat_message {
  color: red;
  max-width: 400px;
  font-family: "Monserrat-Medium";
}
.only_lat_message {
  margin-top: 20px;
}

.btn {
  margin-top: 60px;
  background: #125B50;
  border-radius: 60px;
  padding: 12px 20px;
  font-family: "Monserrat-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  border: none;
  text-transform: capitalize;
  color: #faf5e4;
  cursor: pointer;
}

.btn:hover {
  background-color: #125B50;
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px #0c4139;
  transform: translateY(-1px);
}

.btn[disabled="disabled"],
.btn:disabled {
  opacity: 0.7;
  color: linen;
  background-color: gray;
  cursor: default;
}

.btn:disabled:hover {
  box-shadow: none;
  transform: translateY(0px);
}

.registFon {
  position: relative;
  background-image: url("./../../../../src/Images/fonGirl.svg");
  width: 100%;
  height: 1080px;
}

.textFon {
  position: absolute;
  width: 679px;
  right: 201px;
  top: 682px;
  bottom: 244px;
  text-align: left;
}

.welcome {
  font-family: "Monserrat-medium";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 77px;
  letter-spacing: 0.1em;

  color: #faf5e4;
}
.textConcept {
  font-family: "Monserrat-medium";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1em;

  color: #faf5e4;
}

.textReg {
  font-family: "Monserrat-medium";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.09em;
  opacity: 0.8;
  color: #000000;
}

.reg {
  text-decoration: none;
  color: teal;
}

.reg:hover {
  font-weight: bold;
  color: teal;
}

.reply {
  font: italic 20px/25px Arial;
  color: teal;
  margin-top: 10px;
  max-width: 400px;
}

.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0.5;
}

.icon:hover {
  opacity: 1;
}
.pass {
  position: relative;
}

@media screen and (max-width: 1919px) {
  .registFon {
    height: 768px;
    background-size: cover;

    background-repeat: no-repeat; /* Do not repeat the image */
  }
  .logo {
    text-align: center;
    padding-left: 0px;
  }
  .regist {
    margin-top: 35px;
  }
  .regist h1 {
    font-size: 24px;
  }
  .form label {
    font-size: 12px;
    line-height: 18px;
  }
  .input {
    width: 290px;
    height: 40px;
    font: 16px/16px "Monserrat-Medium";
  }

  .inputError {
    width: 290px;
    height: 40px;
    font: 16px/16px "Monserrat-Medium";
  }
  .error,
  .only_lat_message {
    max-width: 290px;
    font-size: 12px;
  }
  .only_lat_message {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .pass {
    position: relative;
    display: block;
    align-self: flex-start;
  }
  .icon {
    width: 23px;
    height: 20px;
    top: 12px;
  }
  .textReg {
    font-size: 12px;
    display: flex;
    flex-flow: column-reverse;
  }
  .btn {
    align-self: center;
    font-size: 20px;
    padding: 2px 10px;
    width: 190px;
    margin-top: 0px;
  }
  .reply {
    font-size: 12px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 1180px) {
  .registFon {
    background-size: cover;
    text-align: center;
    margin-top: 100px;
    height: calc(400px + (650 - 400) * ((100vw - 550px) / (1130 - 550)));
    background-repeat: no-repeat; /* Do not repeat the image */
  }
}

@media screen and (max-width: 720px) {
  .root {
    display: flex;
    flex-direction: column-reverse;
  }

  .registFon {
    max-width: 200px;
    max-height: 200px;
    margin: 0px auto;
  }

  .regist {
    margin: 0px auto;
  }
}
