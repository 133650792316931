.root {
  margin: 65px 0px auto 10px;
}

.userDate {
  display: flex;
  align-items: center;
}

.balance {
  margin-right: 8px;
}

.balance input {
  font-family: "Monserrat-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  width: 200px;
  height: 27px;
  border: none;
  border-bottom: 1px solid #999999;
  padding-left: 5px;
  background: rgba(250, 245, 228, 0.2);
}

.textBalance {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #999999;
}
.balance_month {
  font-family: "Monserrat-Medium";
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  color: #999999;
}

.datePicker {
  margin-top: 180px;
  text-align: left;
}

@media screen and (max-width: 1919px) {
  .root {
    margin-top: 42px;
  }
  .userAva img {
    width: 50px;
    height: 50px;
  }
  .balance input {
    width: 150px;
    font-size: 16px;
    line-height: 16px;
    padding-left: 3px;
  }
  .textBalance,
  .balance_month {
    font-size: 12px;
  }
  .datePicker {
    margin-top: 130px;
    margin-right: 7px;
    text-align: left;
  }
}
@media (max-width: 1280px) {
  .balance input {
    /* width: 120px; */
  }
}
@media (max-width: 1200px) {
  .balance input {
    max-width: 120px;
    font-size: 14px;
  }

}
@media (max-width: 1130px) {
  .datePicker {
    margin-top: 90px;
  }
  .userDate {
    flex-direction: column-reverse;
  }

  .balance input {
    max-width: 120px;
  }
  
}
@media (max-width: 840px) {
  .datePicker {
    margin-top: 118px;
  }
  .userDate {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
  .textBalance, .balance_month {
    font-size: 10px;
  }
}
@media (max-width: 625px) {
  .datePicker {
    margin-top: 118px;
  }
}


@media screen and (max-width: 376px) {
  .userAva img {
    width: 32px;
    height: 32px;
  }
  .textBalance {
    font-size: 8px;
  }
  .balance_month {
    font-size: 8px;
    margin-left: 4px;
  }
  .balance input {
    font-size: 10px;
    width: 86px;
  }
  .root {
    margin-top: 25px;
  }
  .datePicker {
    margin-top: 50px;
    
    width: 110px;
  }
}
