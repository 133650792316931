.App {
  margin: auto;
  text-align: center;
  max-width: 1920px;
  height: 1080px;
}

.navigation {
  max-width: 405px;
  background-image: url("../src/Images/navBlockBack2.png");
  margin-left: 120px;
}

.logo_block {
  padding-top: 50px;
}
.logo {
  font-family: "Abril Fatface";
  font-size: 28px;
  font-weight: 800;
  color: #155851;
}

.logo_text {
  height: 19px;
  font-family: "PlayfairDisplay-Medium";
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-transform: uppercase;
}
.button_navi_block {
  padding-top: 184px;
  display: flex;
  flex-direction: column;
}
.button {
  width: 405px;
  height: 80px;
  color: #000000;
  margin-bottom: 6px;
  border: 0;
  font-family: "Monserrat-Medium";
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.button:hover {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25), 0px -3px 9px rgba(0, 0, 0, 0.25);
  background: rgba(66, 50, 3, 0.185);
}

.button.active {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25), 0px -3px 9px rgba(0, 0, 0, 0.25);
}

.main_field_title {
  font-family: "Monserrat-Medium";
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 40px;
}

.main_field_title_label {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #999999;
  margin-bottom: 42px;
}
.balance_month {
  font-family: "Monserrat-Medium";
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  color: #999999;
}
.ruble_icon,
.ruble_icon_small {
  font-family: "Monserrat-Regular";
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  position: relative;
  right: 25px;
}
.ruble_icon_small {
  font-size: 20px;
  top: 49px;
}
.ruble_icon_transactions {
  position: relative;
  right: 0px;
  left: 10px;
  color: rgba(0, 0, 0, 0.5);
}
.input_container {
  display: flex;
}
.input_rub {
  width: 265px;
  height: 27px;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-bottom: 5px;
  border: none;
  border-bottom: 1px solid #999999;
  font-family: "Monserrat-Regular";
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  background: rgba(250, 245, 228, 0.2);
}

.ruble_iconMobile {
  font-family: "Monserrat-Regular";
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  color: #54782B;
  position: relative;
  right: 25px;
}
.main_field_string {
  display: flex;
  justify-content: center;
}
.select_element {
  width: 264px;
  height: 60px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  font-family: "Monserrat-Regular";
  font-weight: 400;
  font-size: 24px;
  margin: 28px 19px 0px;
  padding-left: 10px;
  background-color: white;
}
.main_field_string_input {
  width: 254px;
  height: 60px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  font-family: "Monserrat-Regular";
  font-weight: 400;
  font-size: 24px;
  margin-top: 28px;
  padding-left: 10px;
  background-color: #faf5e433;
}

.main_field_string_input[disabled] {
  cursor: auto;
  border: 1px solid #dfdddd;
  background-color: #bbbbbb25;
}

.main_field_string_input::-webkit-inner-spin-button {
  display: none;
}
.main_field_string_button {
  cursor: pointer;
  width: 264px;
  height: 60px;
  border: 1px solid #125B50;
  color: white;
  font-family: "Monserrat-Regular";
  font-weight: 600;
  font-size: 20px;
  margin: 28px 19px 0px;
  background-color: #125B50;
  border-radius: 24px;
}
.main_field_string_button:hover {
  background-color: #125B50;
  cursor: pointer;
  box-shadow: 0px 2px 5px 1px #0c4139;
  transform: translateY(-1px);
}

.main_field_string_button[disabled],
.main_field_string_button_plus[disabled] {
  background: #125B50;
}
.main_field_string_button[disabled]:hover {
  box-shadow: none;
  transform: none;

  cursor: auto;
}

.main_field_string_button_plus {
  display: none;
  cursor: pointer;
  width: 45px;
  border: 1px solid #125B50;
  color: white;
  font-family: "Monserrat-Regular";
  font-weight: 600;
  font-size: 30px;
  margin: 28px 19px 0px;
  background-color: #125B50;
  border-radius: 50%;
}

.main_field_string_button_plus:hover {
  background: rgba(0, 0, 0, 0);
  color: teal;
  box-shadow: inset 0 0 0 3px teal;
}
.main_field_string_button_plus[disabled]:hover {
  background-color: #125B50;
  color: white;
  box-shadow: none;
}

.main_field_analitic_h3 {
  font-family: "Monserrat-Medium";
  font-size: 28px;
  font-weight: 400;
  margin-top: 60px;
}
.option_list,
.option_list_add {
  font-family: "Monserrat-Regular";
  font-weight: 400;
  font-size: 24px;
}
.option_list_add {
  padding-left: 5px;
  font-weight: 600;
  cursor: pointer;
}
.option_list_add:hover {
  background-color: rgba(209, 241, 172, 0.5);
}
.main_field_analitic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  bottom: 105px;
}
.analitic_select_zone {
  display: flex;
  margin-left: 40px;
}

.analitic_select {
  width: 264px;
  height: 50px;
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  padding-left: 18px;
  padding-right: 18px;
  /* margin-right: 166px; */
  background: rgba(250, 245, 228, 0.2);
}
.analitic_select_option {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  height: 25px;
}
.analitic_radio_input {
  position: relative;
  width: 16px;
  height: 16px;
  outline: none;
  margin-right: 24px;
}
.analitic_radio_input::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #54782b;
  background-color: rgb(230, 246, 237);
}
.analitic_radio_input:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #54782b;
  transform: translate(-50%, -50%);
  visibility: visible;
}
.analitic_select_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-left: 166px;
}
.main_field_title_analitic {
  font-size: 32px;
  margin-left: 40px;
}
.label_analitic {
  font-family: "Monserrat-Medium";
  font-size: 24px;
  font-weight: 400;
  width: 547px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}
.label_analitic1 {
  margin-top: 70px;
}
.analitic_string {
  margin: 30px;
}
.analitic_string_down {
  margin: 40px 9px;
  width: 264px;
}
.analitic_button {
  margin: 40px 9px;
}

.gistograms-analitic {
  width: 1300px;
}

.arrowVector {
  display: none;
}

.vectorBtn {
  display: none;
}

.mobileSum {
  display: none;
  margin: 69px auto 150px;
}

.mainTextSumm, 
.balance_month_mobile {
  font-family: "Monserrat-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.input_rubMobile {
  width: 185px;
  height: 27px;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-bottom: 5px;
  border: none;
  background-color: rgba(250, 245, 228, 0.2);
  color: #54782B;
  font-family: "Monserrat-Regular";
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  margin-top: 10px;
}

.main_field_storage {
  display: flex;
}
.select_storage {
  width: 264px;
  height: 60px;
  margin: 28px 20px;
}
.storage_categories {
  margin-top: 68px;
}
.categories_storage_title {
  display: flex;
  width: 599px;
  margin-left: 74px;
  text-align: start;
  vertical-align: middle;
  border-bottom: 1px solid #F4F4F4;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 35px;
}
.category_name_storage {
  width: 231px;
  height: 36px;
  
}
.grand_total_storage {
  width: 208px;
  height: 36px;
  
}
.sum_storage {
  width: 160px;
  height: 36px;
}
.sum_storage_content {
  position: relative;
  top: 5px;
  width: fit-content;
  height: fit-content;
  background: rgba(250, 245, 228, 0.2);
  border: 1px solid #DFE2E9;
  border-radius: 8px;
  line-height: 22px;
}
.fat {
  font-weight: 600;
}
.storage_title {
  text-align: start;
  margin-left: 20px;
}

@media screen and (max-width: 1919px) {
  .App {
    max-width: 1280px;
    height: fit-content;
    min-height: 768px;
  }
  .navigation {
    margin-left: 20px;
  }
  .button_navi_block {
    padding-top: 105px;
  }
  .button {
    width: 295px;
    height: 65px;
    font-size: 20px;
  }
  .main_field_title {
    font-size: 32px;
    margin-bottom: 32px;
  }
  .main_field_title_analitic {
    font-size: 24px;
  }
  .main_field_title_label {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .balance_month {
    font-size: 12px;
  }
  .ruble_icon {
    font-size: 16px;
    right: 18px;
  }
  .ruble_icon_small {
    font-size: 16px;
    top: 40px;
    right: 20px;
  }
  .input_rub {
    width: 190px;
    height: 19px;
    margin-left: 10px;
    font-size: 16px;
  }
  .main_field_string_input {
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
  .main_field_string_button {
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
  .option_list_add {
    font-size: 16px;
    padding: 4px;
  }
  .analitic_select {
    width: 190px;
    height: 40px;
    font-size: 16px;
    padding-left: 10px;
  }
  .analitic_select_option {
    font-size: 16px;
  }
  .analitic_select_form div {
    display: flex;
  }
  .analitic_select_form {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    line-height: 14px;
  }
  .analitic_radio_input {
    width: 14px;
    height: 14px;
  }
  .analitic_radio_input::before {
    width: 10px;
    height: 10px;
  }
  .analitic_radio_input:checked::after {
    width: 6px;
    height: 6px;
  }
}

@media (max-width: 1275px) {
  .main_field_string_button {
    display: none;
  }

  .main_field_string_button_plus {
    display: block;
    margin: 28px 10px 0px;
  }
  .analitic_select_form {
    width: 125px;
    font-size: 12px;
    margin-left: 55px;
  }
  .analitic_select {
    /* margin-right: 55px; */
  }
}

@media (max-width: 1130px) {
  .main_field_string_input {
    width: calc(110px + (190 - 110) * ((100vw - 850px) / (1130 - 850)));
    min-width: 110px;
    font-size: 13px;
  }

  .button {
    width: calc(200px + (295 - 200) * ((100vw - 850px) / (1130 - 850)));
    min-width: 190px;
  }
  .option_list_add {
    font-size: 13px;
  }
}
@media screen and (max-width: 1040px) {
  .ruble_icon_transactions {
    font-size: 12px;
  }
  .main_field_string_input {
    padding-left: 5px;
  }
  .ruble_icon_small {
    right: 15px;
  }
}
@media (max-width: 840px) {
  .mainField {
    grid-template-columns: 1fr minmax(150px, 220px);
  }
  .main_field_analitic {
    bottom: 0px;
  }
  .main_field_title_analitic {
    margin-left: 80px;
    margin-bottom: 40px;
  }
  .analitic_select_zone {
    margin-left: 80px;
  }
  .analitic_select {
    /* margin-right: 40px; */
  }
  .analitic_select_form {
    margin-left: 40px;
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .vectorBtn,
  .arrowVector {
    display: flex;
    height: 15px;
    align-self: center;
  }

  .btnStek {
    display: flex;
  }

  .vectorBtn {
    margin: 0px 10px 0px 15px;
  }
  .arrowVector {
    margin-right: 10px;
  }
}

@media screen and (max-width: 720px) {
  .logo {
    font-size: 20px;
    margin-top: 30px;
    line-height: 32px;
    font-weight: 800;
    color: #125B50;
  }

  .logo_block {
    padding-top: 0px;
  }
}

@media (max-width: 625px) {
  .main_field_input,
  .main_field_title_label,
  .balance_month {
    display: none;
  }
  .main_field_string {
    width: 100%;
    justify-content: space-between;
  }
  .main_field_string_input {
    width: 30vw;
    height: 50px;
  }
  .main_field_string_button_plus {
    width: 50px;
  }
  .ruble_icon_small {
    top: 45px;
    right: 15px;
  }

  .main_field_title {
    margin: 60px 0px 0px 20px;
    text-align: start;
    font-size: 24px;
  }

  .mobileSum {
    display: block;
  }
  .ruble_icon_transactions {
    font-size: 11px;
  }
  .analitic_select_zone {
    margin-left: 20px;
  }
  .main_field_title_analitic {
    margin: 0px 0px 20px 20px;
  }
}
@media screen and (max-width: 595px) {
  .main_field_analitic {
    top: 60px;
  }
  .analitic_select_form {
    width: 100px;
    font-size: 9px;
  }
}
@media screen and (max-width: 376px) {
  .main_field_string_input {
    font-size: 10px;
    width: 110px;
    height: 32px;
    margin-top: 24px;
  }
  .main_field_string_button_plus {
    width: 32px;
    height: 32px;
    line-height: 1px;
    text-align: center;
    margin-top: 24px;
  }
  .ruble_icon {
    font-size: 10px;
  }
  .ruble_icon_small {
    top: 31px;
    font-size: 16px;
  }
  .mobileSum {
    margin-top: 125px;
  }
  .option_list_add {
    font-size: 10px;
  }
}
