.rectangle {
  height: 100%;
  display: flex;
  background: rgba(250, 245, 228, 0.2);
}

.main {
  display: grid;
  width: 100%;
  min-height: 768px;
  /* grid-template-rows: 1fr 200px; */
}
.transactions {
  align-self: flex-end;
}

.mainField {
  display: grid;
  grid-template-columns: 1fr minmax(150px, 400px);
}

.mainFieldBlock {
  padding-top: 171px;
}
.mobile_burg {
  display: none;
}

@media screen and (max-width: 1919px) {
  .mainFieldBlock {
    padding-top: 167px;
  }
}
@media screen and (max-width: 1024px) {
  .aside {
    position: absolute;
    right: 15px;
  }
}

@media (max-width: 840px) {
  .mainField {
    grid-template-columns: 1fr minmax(150px, 220px);
  }

  .main.active {
    filter: blur(2px);
  }
}

@media (max-width: 840px) {
  .mobile_burg {
    display: block;
    position: absolute;
    top: 50px;
    left: 20px;
    cursor: pointer;
    z-index: 10;
  }
}

@media (max-width: 625px) {
  .mainFieldBlock {
    width: 100%;
  }
  .mainField {
    display: flex;
    position: relative;
  }
  .aside {
    position: absolute;
    right: 15px;
  }
}

@media screen and (max-width: 376px) {
  .mobile_burg svg {
    width: 20px;
    height: 14px;
  }
  .mainField {
    display: flex;
  }
  .aside {
    position: absolute;
  }
  .mainFieldBlock {
    padding-top: 67px;
  }
}
