.input_block {
    display: flex;
    flex-direction: column;
}
.input_label {
    align-self: flex-start;
    margin: 6px 0px;
}
.input {
	-webkit-appearance: textfield;
    -moz-appearance: textfield;
	appearance: textfield;
    font-family: "Monserrat-Regular";
    font-weight: 400;
    font-size: 16px;
    width: 190px;
    height: 40px;
    border: 1px solid #b7b7b7;
    border-radius: 8px;
    padding-left: 8px;
    outline: none;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
	display: none;
} 
.input_error {
    border-color: red;
}
.error_message {
    color: red;
    align-self: flex-start;
    margin: 10px 0px;
}
.active {
    border: 1px solid rgb(206, 229, 160);
}

.input_range {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 192px;
    margin: 0px 4px;
    position: relative;
    bottom: 4px;
}

.input_range::-webkit-slider-runnable-track {
    border-radius: 10px/100%;
    height: 4px;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(206, 229, 160);
}
.input_range::-moz-range-track {
    border-radius: 10px/100%;
    height: 2px;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgb(206, 229, 160);
}
.input_range::-webkit-slider-thumb {
    background: #bda037;
    border-radius: 50%;
    cursor: pointer;
    width: 13px;
    height: 13px;
    -webkit-appearance: none;
    margin-top: -5px;
  }
  .input_range::-moz-range-thumb {
    background: #bda037;
    border-radius: 50%;
    cursor: pointer;
    width: 13px;
    height: 13px;
    -moz-appearance: none;
    margin-top: -5px
  }

  @media (max-width: 840px) {
    .input {
        width: 260px;
    }
    .input_range {
        width: 262px;
    }
  }
  @media (max-width: 624px) {
    .input {
        width: 300px;
    }
    .input_range {
        width: 302px;
    }
  }